import React, { useEffect } from "react"

import "./checkbox.scss"

const CheckBox = props => {
  const handleClick = e => {
    props.onChange(props.value)
  }

  useEffect(() => {}, [props.checked])

  if (props.filters) {
    return (
      <div className="form-check  form-check-inline">
        <label>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleClick}
            value={props.value}
            checked={props.checked}
            placeholder={props.placeholder}
          />
          <span className="pl-2 no-color">{props.label}</span>
        </label>
      </div>
    )
  }

  return (
    <div className="form-check form-check-inline">
      <label>
        <input
          className="form-check-input"
          type="checkbox"
          onClick={handleClick}
          defaultChecked={props.checked}
          value={props.value}
          placeholder={props.placeholder}
        />
        <span className="pl-2 no-color">{props.label}</span>
      </label>
    </div>
  )
}

export default CheckBox
